import AboutComponent from "./AboutComponent";
import WorksComponent from "./ExperienceComponent.js";
import "../App.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faNetworkWired,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useRef, useEffect } from "react";

const ExperienceComponent = () => {


  return (
    <div className="content first-content" style={{ paddingBottom: 0 + "px" }}>
      <h2>Experience</h2>

      <div className="tab-container">
        <ul
          className="nav nav-tabs"
          style={{ position: "relative", paddingTop: 50 + "px" }}
        >
          <li id="about" className={`nav-item `} style={{ width: 34 + "%" }}>
            <a
              className="nav-link"
              href="#1"
              style={{
                width: 100 + "%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              About&nbsp; <FontAwesomeIcon icon={faInfoCircle} />
            </a>
          </li>
          <li id="works" className={`nav-item`} style={{ width: 32 + "%" }}>
            <a
              className="nav-link"
              href="#2"
              style={{
                width: 100 + "%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Works&nbsp; <FontAwesomeIcon icon={faNetworkWired} />
            </a>
          </li>
          <li id="contact" className={`nav-item `} style={{ width: 34 + "%" }}>
            <a
              className="nav-link"
              href="#3"
              style={{
                width: 100 + "%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Contact &nbsp; <FontAwesomeIcon icon={faPhone} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ExperienceComponent;
