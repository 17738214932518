import AboutComponent from './AboutComponent';
import ExperienceComponent from './ExperienceComponent.js';
import WorksComponent from './ExperienceComponent.js';
import '../App.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faNetworkWired , faPhone } from '@fortawesome/free-solid-svg-icons';
import Player from './Player';
import { useState, useRef, useEffect } from 'react';
import songs from './audios.js'; // Müzik dosyalarının bulunduğu JS dosyasını içe aktarın



const Layout = () => {
  const [currentSong, setCurrentSong] = useState(songs[0]);
  const [isplaying, setisplaying] = useState(false);
  const audioElem = useRef();

  useEffect(() => {
    if (isplaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  }, [isplaying, currentSong]);

  const onPlaying = () => {
    const duration = audioElem.current.duration;
    const ct = audioElem.current.currentTime;

    setCurrentSong({ ...currentSong, progress: (ct / duration) * 100, length: duration });
  };
    const [activeTab, setActiveTab] = useState('about');

    
    return (
        <div className="content first-content" style={{paddingBottom:0+'px'}}>
          <div className="App">
        <audio src={currentSong.src} ref={audioElem} onTimeUpdate={onPlaying} />
        <Player
          audioElem={audioElem}
          isplaying={isplaying}
          setisplaying={setisplaying}
          currentSong={currentSong}
          setCurrentSong={setCurrentSong}
          songs={songs}
        />
      </div>
        {<AboutComponent />}
      
      <div className="tab-container">
        <ul className="nav nav-tabs" style={{position:'relative', paddingTop:50+'px'}}>
          <li id="about" className={`nav-item `} style={{ width: 34 + '%' }}>
          {/* ${activeTab === 'about' ? 'active' : ''} */}
            <a className="nav-link" href="#1"  style={{width:100+'%',display:'flex', justifyContent:'center', alignItems:'center'}}>
            {/* onClick={() => setActiveTab('about')} */}
              About&nbsp; <FontAwesomeIcon icon={faInfoCircle} />
            </a>
          </li>
          <li id="works" className={`nav-item`} style={{ width: 32 + '%' }}>
            <a className="nav-link" href="#2" style={{width:100+'%',display:'flex', justifyContent:'center', alignItems:'center'}}>
              Works&nbsp; <FontAwesomeIcon icon={faNetworkWired} />
            </a>
          </li>
          <li id="contact" className={`nav-item `} style={{ width: 34 + '%' }}>
          {/* ${activeTab === 'contact' ? 'active' : ''} */}
            <a className="nav-link" href="#3"  style={{width:100+'%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {/* onClick={() => setActiveTab('contact')} */}
              Contact &nbsp;  <FontAwesomeIcon icon={faPhone} />
            </a>
          </li>
        </ul>
      </div>
    </div>
    );
}

export default Layout;
