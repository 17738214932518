import React from 'react';
import '../App.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import image1 from '../img/samet.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faGithub, faDiscord, faSpotify, faLinkedinIn, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const AboutComponent = () => {
    return (
        <div className="container-fluid">
            <div className="col-md-3">
                <div className="author-image"><img src={image1} /></div>
            </div>
            <div className="col-md-9">
                <h2>Samet Solaklar</h2>
                <p style={{textTransform:'uppercase'}}>JUST TRYING TO LEARN SOMETHING ABOUT COMPUTING AND PROGRAMMING BECAUSE LEARNING NEVER ENDS.</p>
                <div className="main-btn"><a href="https://www.github.com/sametsolaklar" target="_blank">Github <FontAwesomeIcon icon={faGithub} /> </a></div>
                <div className="main-btn"><a href="https://www.linkedin.com/in/sametsolaklar/" target="_blank">LinkedIn <FontAwesomeIcon icon={faLinkedinIn} /> </a></div>
            </div>
        </div>
    );
}

export default AboutComponent;
