// src/songs.js
import song1 from '../img/queen.mp3';
import song2 from '../img/iamspeed.mp3';
import song3 from '../img/interstaller2.mp3';

import song1img from '../img/queenimg.webp';
import song2img from '../img/mcqueen.webp';
import song3img from '../img/interstaller1.jpg';




const audios = [
    { id: 1, title: 'Hans Zimmer - Interstaller Official Soundtrack', src: song3 , image:song3img},
  { id: 2, title: 'Queen - Bohemian Rhapsody', src: song1 , image:song1img},
  { id: 3, title: 'Lightning McQueen - I Am Speed', src: song2 , image:song2img}
  
];

export default audios;