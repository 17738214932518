import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faNetworkWired , faPhone } from '@fortawesome/free-solid-svg-icons';
import Layout from './components/Layout';
import WorksComponent from './components/WorksComponent.js';
import ExperienceComponent from './components/ExperienceComponent.js'
import { useState, useRef, useEffect } from 'react';


function App() {
  return (
    <div>
      
      <div className="video-background">
        <div className="video-foreground">
          <iframe
            src="https://www.youtube.com/embed/RT4hEmX5OJ0?t=040&controls=0&showinfo=0&version=3&rel=0&vq=hd1080p60&autoplay=1&mute=1&loop=1"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="slides" data-bs-spy="scroll" data-bs-target="#nav-tabs" data-bs-smooth-scroll="true">
        <div className="slide" id="1">
          <Layout />
        </div>
        <div className="slide" id="2"> 
          <WorksComponent />
        </div>
        <div className="slide" id="3">
          <ExperienceComponent />
        </div>
      </div>
    </div>
  );
}

export default App;
